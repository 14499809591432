import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInsideEn14'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import HeaderEn from '../blocks/header/HearderEn';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';

const ServicesInsideEn11 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    const whyList = [
        {
            "title": "New Technology",
            "content": "A brand new skin care treatment that combines oil-infused capsules with MIT double technologies."
        },
        {
            "title": "Safe and effective",
            "content": "56 clinical evidence with 2 patents, using new and awarded biotech active ingredients."
        },
        {
            "title": "Versatile",
            "content": "Suitable for all ages and skin types and problems."
        },
        {
            "title": "Instant results",
            "content": "Needle-free and non-invasive, the first care can present medical beauty treatment."
        }
    ]
    const titleStyle = {
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    };
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark" position='relative' />

            <main id="main" className="site-main" style={{ paddingTop: "7.368rem", paddingBottom: "7.368rem" }}>
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/wishpro.png" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction lang='en' list={['']} tips={'WISH Pro is an innovative skin treatment device that combines four different proven technologies. Together with advanced cosmetic capsules- can see results in only 15 minutes of non-invasive treatment. The skin will be rejuvenated, more radiant, moisturized and look firmer and healthier.'}></Introduction>


                                    <WhyComponent list={whyList} title='Why WISH Pro?' />
                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr" >The 13 types of capsule essences are suitable for all skin types and issues, making it easy to achieve a smooth, bright, and translucent porcelain-like complexion.

                                            </h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <div style={{ paddingBottom: '2rem' }}>
                                                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000000', marginTop: '10px' }}>Active Pro-Long Line</div>
                                                            <div style={{ padding: '1rem 2rem' }}>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Neo Energy</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>A pure bio-energy source for moister and nourished skin, no needle needed for reduction of deep wrinkles</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Repairing</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Stimulates cellular repair and wound healing, improves skin elasticity, firmness and  a visible reduction of wrinkles</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Brightening</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Lightens and evens out skin tone by blocking epidermal melanin and regulating biosynthesis</span>
                                                                </li>
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingBottom: '2rem' }}>
                                                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000000', marginTop: '10px' }}>Infusion Line</div>
                                                            <div style={{ padding: '1rem 2rem' }}>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Hyaluronic</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Increases the skin's capacity to retain water, prevents dehydration, repairs and moisturizes the skin by stimulating collagen and Elastin synthesis</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Collagen</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Contains a pure collagen base to protect the skin from harmful radiation, reinforce your immune system and fight free radicals to slow down skin aging and cell degradation</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>BTX</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Reduces wrinkles by limiting facial muscle contractions as a topical treatment that mimics the effects of Botox® in the skin without the negative side effects</span>
                                                                </li>
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingBottom: '2rem' }}>
                                                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000000', marginTop: '10px' }}>Natural Line</div>
                                                            <div style={{ padding: '1rem 2rem' }}>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Rejuvenation</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Contains Shea butter rich in Vitamins A, E and F. Rejuvenates damaged cells, while restoring elasticity and tone. Rejuvenation provides the skin with essential fatty acids and the nutrients necessary to protect and regenerate the skin.</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Calming Milk</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Using the antiseptic, antibacterial properties of this Calendula oil based product, your skin can look more refreshed, better nourished and win the battle against annoying skin infections.</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Peel Exfoliator</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Renews and revitalizes your skin. Restores a youthful glow by applying Sweet Almond Oil enriched with cellulose peeling containing Vitamins E and A.</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Anti Aging Serum</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Powerful antioxidants in the Jojoba oil help smooth fine lines and wrinkles. A perfect natural moisturizer and prevents dehydration</span>
                                                                </li>
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingBottom: '2rem' }}>
                                                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#000000', marginTop: '10px' }}>Aging Process Control</div>
                                                            <div style={{ padding: '1rem 2rem' }}>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Aging Control</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Water based emulsion contained Progeline peptide has a direct effect on the epidermis and the dermis. It has a remodeling effect, improves sagging skin and wrinkles appearance.</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Care</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Based on Abyssine® 657 - exopolysaccharides from extremophile living in deep sea hydrothermal vent.</span>
                                                                </li>
                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                    <span className="list-group-title" style={titleStyle}>Contour</span>
                                                                    <span className="list-group-price" style={{ maxWidth: '80%', width: '100%', textAlign: 'left', lineHeight:'2rem' ,fontSize:'1.5rem'}}>Aims to restore a youthful appearance, anti aging for sensitive skin, anti dark circles to the human face by targeting specific depths of facial structures and treat localized facial concerns such as wrinkles, skin laxity, hyper pigmentation and scars.</span>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn11;