import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import PageTitleServicesInside8 from '../blocks/page-title/PageTitleServicesInside8';
import Introduction from '../components/services/Introduction';

const ServicesInside6 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const contentStyle = { 
        maxWidth: '80%',
        width: '100%', 
        textAlign: 'left',
        fontSize:'1.5rem'
    }
    const titleStyle = {
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    };
    const list = [
        'SECRET RF 黄金微针是一种用于皮肤提升、疤痕、紧致和年轻化的领先技术，使用精确控制的双极射频与微创绝缘或非绝缘微针相结合。强大的技术组合可诱导真皮凝固，从而收紧皮肤、皱纹和毛孔缩小、痤疮疤痕治疗和腋窝多汗症。',
        '射频和微针之间的协同作用提供了技术最先进的皮肤再生疗法之一。与基于点阵激光的治疗相比，它还有助于显着减少治疗时间和停机时间。所有皮肤类型都可以通过 SECRET RF 进行治疗，热损伤的风险非常低。',
        'SECRET RF 的工作原理是通过微创、精密设计的微针将精确控制的射频能量直接施加到皮肤的特定深度。 Secret RF 对于治疗深层和浅层皱纹以及痤疮疤痕特别有用。',
        '是澳大利亚最受欢迎的射频针刺设备之一。',
    ]


    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside8 />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/黄金微针.jpg" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction textIndent={true} list={list} tips={'微针射频治疗疤痕、改善毛孔和紧致皮肤。'} tipsSize={'32px'} title={'为什么选择黄金微针？'}></Introduction>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">治疗范围</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>改善色调和质感</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>细纹和皱纹</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>痤疮和手术疤痕</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90-120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>妊娠纹</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>缩小毛孔</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>细纹颈纹</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside6;