import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const AboutsUs = () => {
    const location = useLocation();
    const hash = location.hash;
    console.log(hash, 'sadasdasd');
    useEffect(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
            element.scrollIntoView(true);
        }
    }, []);
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper" id="xini">
                <div className="row gutter-width-sm" >
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>Laydeez Laser Clinic 位于维多利亚州墨尔本 Box Hill 中心地带。</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p style={{fontSize:"1.5rem"}}>继悉尼 Burwood 店后，我们的第二家店在众望所期中开始了在墨尔本的旅程，我们店铺位于墨尔本最大华人区 box hill 的中心地带，拥有占地两百平的超大规模，集产后护理，日式小颜整骨，皮肤管理，体态管理为一体，并本着不变的初心继续为墨尔本的华人及女性服务。</p>
                        </div>
                    </div>
                    {/* <div class="col-xl-2 col-lg-2 d-none d-lg-block"></div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p>免去五次治疗</p>
                            <p>未来是未知的</p>
                            <p>100%非侵入性</p>
                            <p>快速治疗</p>
                            <p>无需恢复时间</p>
                            <p>舒适的治疗</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
