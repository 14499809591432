import React, { Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import ContactUs from '../blocks/contacts/ContactUs';

import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactForm from '../components/form/ContactForm';

import GoogleMaps from '../blocks/google-maps/GoogleMaps';
import GoogleMapsMorb from '../blocks/google-maps/GoogleMapsMorb';

import ContactsInside from '../blocks/contacts/Contacts';
import ContactsMorb from '../blocks/contacts/ContactsMorb';

const Contacts = () => {
    document.body.classList.add('page');

    const itemStyle = {
        marginLeft: '5rem',
    };

    const [showXini, setShowXini] = useState(false);
    const [showMorb, setShowMorb] = useState(false);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>联系我们 | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main"  style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleContacts />

                <div style={{ padding: '7.368rem 0', backgroundColor: "#efe6e8", marginTop: "7.368rem" }}>
                            <h2 style={{ textAlign: 'center', paddingBottom: '3.158rem', marginottom: '0' }}>为什么选择我们？</h2>
                            <div style={{ fontSize: "24px", padding: "0 7.368rem", lineHeight: "30px", textIndent: "3rem" }}>Laydeez 是一家专注于皮肤管理、身体护理和产后修复的诊所，致力于为女性提供全面的护理和支持。我们提供一系列专业的皮肤管理疗程、身体护理和产后修复方案，帮助女性在身体和心理上重获自信。我们的团队由经验丰富的专业人士组成，采用先进的技术、设备和个性化的护理方案，确保每位客户都能获得最佳的体验与效果。无论是想要改善皮肤或身体状态，还是需要帮助恢复产后形体，Laydeez 都是你理想的选择。欢迎加入我们，开启你的美丽与修复之旅！</div>
                </div>
                <div style={{height:'3rem'}}></div>
                <ContactUs addBgClass={false}></ContactUs>
                {/* <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                <h2 style={{ textAlign: "center" }}>悉尼</h2>
                                <GoogleMaps />
                                <div style={{ maxHeight: showXini ? '10000px' : '0px', transition: 'all .3s', overflow: 'hidden' }}>

                                    <div className="spacer" style={{ padding: "3rem 0px", backgroundColor: 'rgb(239, 230, 232)', borderRadius: " 0 0 10px 10px" }}>
                                        <div className="title" style={itemStyle}>
                                            <h2>我们的联系方式</h2>
                                        </div>

                                        <div className="row gutter-width-sm with-pb-xl spacer p-top-lg">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <ContactsInside />
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                                <div className="contact-form-shortcode" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <img src="assets/img/placeholder/Laydeez-wechat qrcode.png  " alt="描述性文本" style={{ width: '30%', height: '30%', marginLeft: '50%' }} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="contacts-item" style={{paddingTop:"3rem"}}>
                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>停车位图片</h6>
                                            </div>
                                            <div style={{padding:"1.579rem 5rem 0"}}>
                                                <div className='row'>

                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'><img src='assets/img/placeholder/悉尼停车.jpg'></img></div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'><img src='assets/img/placeholder/悉尼停车2.jpg'></img></div>
                                                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'><img src='assets/img/placeholder/悉尼停车3.jpg'></img></div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <h4 style={{ textAlign: "center", paddingTop: "3rem", cursor: 'pointer' }} onClick={() => setShowXini(!showXini)}>{showXini ? '收起' : '展示更多'}</h4>


                            </div>
                            <div className="clearfix p-top-lg" style={{ marginTop: '8rem' }}>
                                <h2 style={{ textAlign: "center" }}>墨尔本</h2>
                                <GoogleMapsMorb />

                                <div style={{ maxHeight: showMorb ? '10000px' : '0px', transition: 'all .3s', overflow: 'hidden' }}>

                                    <div className="spacer" style={{ padding: "3rem 0px", backgroundColor: 'rgb(239, 230, 232)', borderRadius: " 0 0 10px 10px" }}>
                                        <div className="title" style={itemStyle}>
                                            <h2>我们的联系方式</h2>
                                        </div>

                                        <div className="row gutter-width-sm with-pb-xl spacer p-top-lg">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <ContactsMorb />
                                            </div>

                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                                <div className="contact-form-shortcode" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <img src="assets/img/placeholder/墨尔本微信.jpeg" alt="描述性文本" style={{ width: '30%', height: '30%', marginLeft: '50%' }} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <h4 style={{ textAlign: "center", paddingTop: "3rem", cursor: 'pointer' }} onClick={() => setShowMorb(!showMorb)}>{showMorb ? '收起' : '展示更多'}</h4>

                            </div>
                        </div>
                    </div>
                </div> */}
            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
