import React, { Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import HeaderEn from '../blocks/header/HearderEn';
import Footer from '../blocks/footer/Footer';
import ContactUsEn from '../blocks/contacts/ContactUsEn';

import PageTitleContactsEn from '../blocks/page-title/PageTitleContactsEn';
import ContactForm from '../components/form/ContactForm';

import GoogleMaps from '../blocks/google-maps/GoogleMaps';
import ContactsInsideEn from '../blocks/contacts/ContactsEn';
import GoogleMapsMorb from '../blocks/google-maps/GoogleMapsMorb';
import ContactsEnMorb from '../blocks/contacts/ContactsEnMorb';

import ContactsInside from '../blocks/contacts/Contacts';
import ContactsMorb from '../blocks/contacts/ContactsMorb';

const Contacts = () => {
    document.body.classList.add('page');

    const itemStyle = {
        marginLeft: '5rem',
    };

    const [showXini, setShowXini] = useState(false);
    const [showMorb, setShowMorb] = useState(false);

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>联系我们 | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark" position='relative' />

            <main id="main" className="site-main" style={{ paddingTop: "7.368rem", paddingBottom: "7.368rem" }}>
                <PageTitleContactsEn />

                <div style={{ padding: '7.368rem 0', backgroundColor: "#efe6e8", marginTop: "7.368rem" }}>
                    <h2 style={{ textAlign: 'center', paddingBottom: '3.158rem', marginottom: '0' }}>why select us?</h2>
                    <div style={{ fontSize: "24px", padding: "0 7.368rem", lineHeight: "30px" }}>Laydeez is a clinic specializing in skin management, body care and postpartum recovery, dedicated to providing comprehensive care and support to women. We offer a range of professional skin management treatments, body care and postpartum recovery solutions to help women regain their physical and mental confidence. Our team is composed of experienced professionals and uses advanced technology, equipment and personalized care plans to ensure that each customer gets the best experience and results. Whether you want to improve your skin or restoration!</div>
                </div>
                <div style={{ height: '3rem' }}></div>
                <ContactUsEn addBgClass={false}></ContactUsEn>


            </main>

            <Footer />
        </Fragment>
    );
};

export default Contacts;
