import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside7'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';

const ServicesInside8 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const contentStyle = { 
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    }
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/水光针图片.png" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">服务项目</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>水润保湿</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>祛斑提亮</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>玻尿酸瀑布护理</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90-120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>细胞再生</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>抗老祛皱</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={contentStyle}>敏感修复</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{flexShrink:"0"}}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside8;