import React, { Fragment, useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/HearderEn';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInsideCom'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServicesEn';
import HeaderEn from '../blocks/header/HearderEn';
import Introduction from '../components/services/Introduction';

const ServicesInsideEn15 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const titleStyle = {
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    };
    const list = [
        'Comprehensive multi-application platform to treat a versatile range of medical aesthetic conditions, covering 72 FDA-cleared indications.',
        'This multi-application platform is a powerful and versatile solution for a wide range of aesthetic treatments, maximizing the potential of numerous energy-based technologies, achieving outstanding results, and creating endless business opportunities.',
        'Featuring innovative one-of-a-kind technologies, the Alma Harmony XL Pro treats 72 FDA- cleared medical aesthetic indications, making it the most versatile multimodality platform on the market.',
        'New innovations include depth control to precisely target different layers of the tissue, a fractional q-switch laser, multi-colour tattoo removal, unique In-MotionTM technology for enhanced IPL/DPL safety profile, and a revolutionary acne treatment combining laser with vacuum technology.',
    ];

    const contentStyle = {
        maxWidth: '80%', 
        lineHeight:'28px',
        fontSize:'1.5rem'
    }
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <HeaderEn logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                    <PageTitleServicesInside str={'Alma Harmony Laser'} />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <img src="assets/img/placeholder/Harmony 治疗.jpg" style={{objectFit:'contain'}} alt="Hair Treatments" />
                                                    <img src="assets/img/placeholder/HARMONY.jpg" style={{objectFit:'contain'}} alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Introduction list={list} title={'Feature'}  lang='en' ></Introduction>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}


                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">Indications</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Clear Lift</span>
                                                            <span className="list-group-price" style={contentStyle}>Tone - pigmented lesions<br/><br/>The effects of acne and aging can result in accumulated scarring and open pores. Alma's texture treatments trigger the skin's natural healing process, enabling dermal remodeling that improves skin texture and reveals smoother skin.</span>
                                                        </li>   
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>SR</span>
                                                            <span className="list-group-price" style={contentStyle}>Skin elasticity & firmness<br/><br/>Uneven skin tone is a common concern resulting from sun exposure, hormonal changes, genetics, environmental effects and more. Alma's pigmentation treatments fade and lighten these lesions, creating a more even skin tone.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>NIR</span>
                                                            <span className="list-group-price" style={contentStyle}>Fine lines & wrinkles<br/><br/>As collagen production decreases with time, areas of lax, sagging skin appear around the face and neck. Alma's skin rejuvenation treatments stimulate the synthesis of collagen and elastin fibers, providing mature skin with a younger, plumper look.<br/><br/>As skin ages, it loses strength, flexibility and elasticity due to a reduction in collagen and elastin fibers. This causes grooves to form and develop into wrinkles. Alma offers the optimal non-invasive technique for reducing the appearance of wrinkles.</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Clear Skin</span>
                                                            <span className="list-group-price" style={contentStyle}>Hydradermabrasion– enviromental damage<br/><br/>Research shows that pollutants, toxins and chemicals in the urban environment and in makeup products damage and clog the skin. Alma's three-step DermaClear hydradermabrasion treatment deeply yet gently exfoliates, cleanses, hydrates and nourishes the skin for an immediate glowing effect.</span>
                                                        </li>

                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price">90-</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price">90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price">From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInsideEn15;