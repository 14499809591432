import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import AboutEn from './pages/AboutEn';
import Contacts from './pages/Contacts';
import ContactsEn from './pages/ContactsEn';
import News from './pages/News';
import NewsSinglePost from './pages/NewsSinglePost';
import Gallery from './pages/Gallery';
import SearchResults from './pages/SearchResults';
import Services from './pages/Services';
import ServicesInside from './pages/ServicesInside';
import page404 from './pages/404';
import UserInterface from './pages/UserInterface';
import ServicesInside1 from './pages/ServicesInside1';
import ServicesInside2 from './pages/ServicesInside2';
import ServicesInside3 from './pages/ServicesInside3';
import ServicesInside4 from './pages/ServicesInside4';
import ServicesInside5 from './pages/ServicesInside5';
import ServicesInside6 from './pages/ServicesInside6';
import ServicesInside7 from './pages/ServicesInside7';
import ServicesInside8 from './pages/ServicesInside8';
import ServicesInside9 from './pages/ServicesInside9';
import ServicesInside10 from './pages/ServicesInside10';
import ServicesInside11 from './pages/ServicesInside11';
import ServicesInside12 from './pages/ServicesInside12';
import ServicesInside13 from './pages/ServicesInside13';
import ServicesInside14 from './pages/ServicesInside14';
import ServicesInside15 from './pages/ServicesInside15';
import ServicesInside16 from './pages/ServicesInside16';
import ServicesInside17 from './pages/ServicesInside17';
import ServicesInsideEn1 from './pages/ServicesInsideEn1';
import ServicesInsideEn2 from './pages/ServicesInsideEn2';
import ServicesInsideEn3 from './pages/ServicesInsideEn3';
import ServicesInsideEn4 from './pages/ServicesInsideEn4';
import ServicesInsideEn5 from './pages/ServicesInsideEn5';
import ServicesInsideEn6 from './pages/ServicesInsideEn6';
import ServicesInsideEn7 from './pages/ServicesInsideEn7';
import ServicesInsideEn8 from './pages/ServicesInsideEn8';
import ServicesInsideEn9 from './pages/ServicesInsideEn9';
import ServicesInsideEn10 from './pages/ServicesInsideEn10';
import ServicesInsideEn11 from './pages/ServicesInsideEn11';
import ServicesInsideEn12 from './pages/ServicesInsideEn12';
import ServicesInsideEn13 from './pages/ServicesInsideEn13';
import ServicesInsideEn14 from './pages/ServicesInsideEn14';
import ServicesInsideEn15 from './pages/ServicesInsideEn15';
import ServicesInsideEn16 from './pages/ServicesInsideEn16';
import ServicesInsideEn17 from './pages/ServicesInsideEn17';
import MelbourneStore from './pages/MelbourneStore';
import SydneyStore from './pages/SydneyStore';
import MelbourneStoreEn from './pages/MelbourneStoreEn';
import SydneyStoreEn from './pages/SydneyStoreEn';
// import ServicesInsideEn1 from './pages/ServicesInsideEn1';
import HomeEN from './pages/HomeEN';
import ServicesInsideEn from './pages/ServicesInsideEn';
import ServicesEn from './pages/ServicesEn';



function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/"}`}
                    component={Home}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/HomeEN"}`}
                    component={HomeEN}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/about-us"}`}
                    component={About}
                />

                <  Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/about-usEn"}`}
                    component={AboutEn}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/contacts"}`}
                    component={Contacts}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/contactsEn"}`}
                    component={ContactsEn}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/news"}`}
                    component={News}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/gallery"}`}
                    component={Gallery}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/news-single-post"}`}
                    component={NewsSinglePost}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/search-results"}`}
                    component={SearchResults}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services"}`}
                    component={Services}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/servicesEn"}`}
                    component={ServicesEn}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/ui"}`}
                    component={UserInterface}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside"}`}
                    component={ServicesInside}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn"}`}
                    component={ServicesInsideEn}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside1"}`}
                    component={ServicesInside1}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn1"}`}
                    component={ServicesInsideEn1}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside2"}`}
                    component={ServicesInside2}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn2"}`}
                    component={ServicesInsideEn2}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside3"}`}
                    component={ServicesInside3}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn3"}`}
                    component={ServicesInsideEn3}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside4"}`}
                    component={ServicesInside4}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn4"}`}
                    component={ServicesInsideEn4}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside5"}`}
                    component={ServicesInside5}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn5"}`}
                    component={ServicesInsideEn5}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside6"}`}
                    component={ServicesInside6}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn6"}`}
                    component={ServicesInsideEn6}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside7"}`}
                    component={ServicesInside7}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn7"}`}
                    component={ServicesInsideEn7}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside8"}`}
                    component={ServicesInside8}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn8"}`}
                    component={ServicesInsideEn8}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside9"}`}
                    component={ServicesInside9}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn9"}`}
                    component={ServicesInsideEn9}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside10"}`}
                    component={ServicesInside10}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn10"}`}
                    component={ServicesInsideEn10}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside11"}`}
                    component={ServicesInside11}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside12"}`}
                    component={ServicesInside12}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside13"}`}
                    component={ServicesInside13}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside14"}`}
                    component={ServicesInside14}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside15"}`}
                    component={ServicesInside15}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside16"}`}
                    component={ServicesInside16}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside17"}`}
                    component={ServicesInside17}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn11"}`}
                    component={ServicesInsideEn11}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn12"}`}
                    component={ServicesInsideEn12}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn13"}`}
                    component={ServicesInsideEn13}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn14"}`}
                    component={ServicesInsideEn14}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn17"}`}
                    component={ServicesInsideEn17}
                />

                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn15"}`}
                    component={ServicesInsideEn15}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-insideEn16"}`}
                    component={ServicesInsideEn16}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/melbourneStore"}`}
                    component={MelbourneStore}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/sydneyStore"}`}
                    component={SydneyStore}
                />
                      <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/melbourneStoreEn"}`}
                    component={MelbourneStoreEn}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/sydneyStoreEn"}`}
                    component={SydneyStoreEn}
                />
                <Route exact component={page404} />
            </Switch>
        </Router>
    );
}

export default App;
