import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInsideCom'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import Introduction from '../components/services/Introduction';
import WhyComponent from '../components/services/WhyComponent';

const ServicesInside11 = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const titleStyle = {
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    };
    const contentStyle = { 
        maxWidth: '80%',
        width: '100%', 
        textAlign: 'left',
        fontSize:'1.5rem'
    }
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    const whyList = [
        {
            title: '1. 腹直肌修复',
            content: "在怀孕期间，腹部肌肉承受着巨大的压力，导致腹直肌分离（Diastasis Recti）现象。腹直肌修复通过传统的治疗手法，帮助重塑核心肌群，增强腹部力量，改善姿势，减轻腰背部不适，提升整体功能。"
        },
        {
            title: '2. 假胯宽修复',
            content: "很多女性在产后会出现骨盆区域的变化，可能导致假胯宽的现象。这种情况不仅影响外观，也可能引发下肢的疼痛和不适。通过针对性的治疗，可以调整骨盆位置，改善腿部线条，增强肌肉稳定性，恢复身形。"
        },
        {
            title: '3. 耻骨修复',
            content: "产后耻骨区域可能会受到损伤或变形，导致疼痛和活动受限。耻骨修复项目通过专业的整骨手法，帮助恢复耻骨的正常功能，缓解疼痛，促进血液循环，提升运动能力。"
        },
        {
            title: '4. 盆骨修复',
            content: "盆骨在分娩过程中承受了巨大的压力，可能出现不适或功能障碍。盆骨修复结合整骨手法，旨在恢复盆骨的结构和功能，减少疼痛，提升盆底肌肉的力量，改善尿失禁等问题，增强整体的身体素质。"
        },
        {
            title: '5. 产后形体管理',
            content: "产后形体管理着重于全面提升身体形态和健康水平。通过系统调整，帮助新妈妈们重拾自信，塑造优美的身形，改善体态，增强体能。"
        }
    ]
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside str={'产后修复'} />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover" style={{ display: "flex", justifyContent: "space-around", columnGap: "1rem" }}>
                                                    <img src="assets/img/placeholder/产后修复.jpg" alt="Hair Treatments" />
                                                    <img src="assets/img/placeholder/产后修复2.jpg" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}
                                    <Introduction textIndent={true} list={['产后修复是许多新妈妈关注的重要课题。通过针对性的治疗项目，妈妈们可以有效地恢复身体的功能与美感，重拾自信。在专业康复师的指导下，制定个性化的修复计划，以达到最佳效果。']}></Introduction>
                                    <WhyComponent list={whyList}  title='治疗项目介绍'/>

                                   {false && <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">治疗项目介绍</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>抗衰老精华</span>
                                                            <span className="list-group-price" style={contentStyle}>荷荷巴油中强大的抗氧化剂有助于抚平细纹和皱纹。完美的天然保湿剂，防止脱水</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>BTX</span>
                                                            <span className="list-group-price" style={contentStyle}>通过限制面部肌肉收缩来减少皱纹，作为一种局部治疗，模仿肉毒®杆菌毒素在皮肤中的作用，而没有负面的副作用</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>新能源</span>
                                                            <span className="list-group-price" style={contentStyle}>纯生物能源，让肌肤更滋润滋养，无需针头即可减少深层皱纹</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>年龄控制</span>
                                                            <span className="list-group-price" style={contentStyle}>含有 Progeline 肽的水基乳液对表皮和真皮有直接影响。它具有重塑作用，改善皮肤松弛和皱纹的外观。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>镇静牛奶</span>
                                                            <span className="list-group-price" style={contentStyle}>使用这种金盏花油基产品的防腐、抗菌特性，您的皮肤可以看起来更清爽、更滋养，并赢得与烦人的皮肤感染的战斗。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>胶原</span>
                                                            <span className="list-group-price" style={contentStyle}>含有纯胶原蛋白基质，可保护皮肤免受有害辐射，增强免疫系统并对抗自由基，从而延缓皮肤老化和细胞退化</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>修复</span>
                                                            <span className="list-group-price" style={contentStyle}>刺激细胞修复和伤口愈合，改善皮肤弹性、紧致度并明显减少皱纹</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>轮廓</span>
                                                            <span className="list-group-price" style={contentStyle}>旨在恢复年轻的外观，抗衰老敏感皮肤，通过针对面部结构的特定深度来对抗人脸黑眼圈，并治疗局部面部问题，如皱纹、皮肤松弛、色素沉着过度和疤痕。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>去角质剂</span>
                                                            <span className="list-group-price" style={contentStyle}>更新和活化您的皮肤。使用富含维生素 E 和 A 的纤维素去角质的甜杏仁油，恢复年轻光泽。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>透明质</span>
                                                            <span className="list-group-price" style={contentStyle}>通过刺激胶原蛋白和弹性蛋白的合成，增加皮肤的保水能力，防止脱水，修复和滋润皮肤</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>白粉</span>
                                                            <span className="list-group-price" style={contentStyle}>通过阻断表皮黑色素并调节生物合成的程序淡化和均匀肤色</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>关心</span>
                                                            <span className="list-group-price" style={contentStyle}>基于 Abyssine® 657 - 来自生活在深海热液喷口的极端微生物的胞外多糖。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>回春</span>
                                                            <span className="list-group-price" style={contentStyle}>含有富含维生素 A、E 和 F 的乳木果油，使受损细胞恢复活力，同时恢复弹性和色调。Rejuvenation 为皮肤提供必需脂肪酸和保护皮肤再生所需的营养物质。</span>
                                                        </li>



                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside11;