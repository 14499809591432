import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import PageTitleServicesInsideCom from '../blocks/page-title/PageTitleServicesInsideCom';
import AboutUs from '../blocks/about/AboutUs';
import GoogleMaps from '../blocks/google-maps/GoogleMaps';
import ImageShow from '../components/services/ImageShow';
import Contacts from '../blocks/contacts/Contacts';

const SydneyStore = () => {
    document.body.classList.add('home');
    document.body.classList.add('header-absolute-true');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    const itemStyle = {
        marginLeft: '5rem',
    };
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Laydeez skin laser clinic</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <ImageShow title='悉尼分店' backgrounds={[
                            'url(assets/img/placeholder/悉尼店面1.jpg)',
                            'url(assets/img/placeholder/悉尼店面6.jpg)',
                            'url(assets/img/placeholder/悉尼店面3.jpg)',
                            'url(assets/img/placeholder/悉尼店面4.jpg)',
                            'url(assets/img/placeholder/悉尼证书.jpg)',
                            'url(assets/img/placeholder/悉尼产品.jpg)',
                            'url(assets/img/placeholder/悉尼治疗室.jpg)',
                            'url(assets/img/placeholder/悉尼治疗室2.jpg)',
                        ]} />
                        {/* <PageTitleServicesInsideCom str='悉尼分店'></PageTitleServicesInsideCom> */}

                        <AboutUs></AboutUs>
                        <div className='block spacer p-top-xl'>
                            <div className='row wrapper align-item-center' style={{ width: "100%" }}>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                    <GoogleMaps></GoogleMaps>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                    <Contacts></Contacts>
                                </div>
                            </div>

                            
                            <div className="contacts-item wrapper" style={{ paddingTop: "3rem" }}>
                            <h2>停车位图片</h2>

                                <div style={{ padding: "1.579rem 0" }}>
                                    <div className='row'>
                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                                            <img style={{objectFit:"cover", width: "100%", height: "300px"}} src='assets/img/placeholder/悉尼停车.jpg'></img>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                                            <img style={{objectFit:"cover", width: "100%", height: "300px"}} src='assets/img/placeholder/悉尼停车2.jpg'></img>
                                        </div>
                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                                            <img style={{objectFit:"cover", width: "100%", height: "300px"}} src='assets/img/placeholder/悉尼停车3.jpg'></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default SydneyStore; 
