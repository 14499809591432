import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const AboutsUs = () => {

    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper" id="xini">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>Laydeez skin laser clinic坐落于新南威尔士州悉尼中心最繁华的华人区Burwood的主街。</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p style={{fontSize:"1.5rem"}}>我们集：皮肤管理，抗衰治疗，镭射祛斑，产后修复，日式小颜术，为一体的多纬度的医学美容中心。我们拥有当下最先进的仪器以及十年以上经验丰富的团队，为您提供个性化和定制化的专业医美服务和产后修复服务。在每一位客人到来之际，我们都将用最诚挚的热情以及专业的角度为您所困扰的皮肤及各种问题提供专业的意见。</p>
                        </div>
                    </div>
                    {/* <div class="col-xl-2 col-lg-2 d-none d-lg-block"></div>
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p>免去五次治疗</p>
                            <p>未来是未知的</p>
                            <p>100%非侵入性</p>
                            <p>快速治疗</p>
                            <p>无需恢复时间</p>
                            <p>舒适的治疗</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
