import React from 'react';

const Introduction = ({ list,title,tips,textIndent=false,tipsSize,lang }) => {
    // 确保 list 不是 null 或 undefined，并且是一个数组
    if (!Array.isArray(list) || list.length === 0) {
      return (
        <div style={{ fontSize: "28px", lineHeight: "36px", color: "#000" }} className='p-top-xl spacer block' >
          <p>No projects available.</p>
        </div>
      );
    }
  
    return (
      <div className='p-top-xl spacer block' >
        {tips && <div style={{color:'#000', fontStyle:lang==='en' ? 'italic':"normal", paddingBottom:'2rem', textIndent: textIndent&&!tipsSize ? '2em' : '0', fontSize: tipsSize?'2.421rem':'1.5rem', lineHeight: '3rem' }}>{tips}</div>}
        {title&&<h2 style={{paddingTop:'2rem'}}>{title}</h2>}
        {list && list.map((project, index) => (
          <div style={{  lineHeight: "2rem",fontStyle:lang==='en' ? 'italic':"normal",paddingBottom:'2rem', color: "#000",textIndent: textIndent ? '2em' : '0',fontSize:"1.5rem" }} key={index}>{project}</div>
        ))}
      </div>
    );
  };
  
export default Introduction
