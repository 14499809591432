import React from 'react';

const ContactsEn = () => {
    // 定义统一的样式对象
    const itemStyle = {
        marginLeft: '5rem', 
    };
    const fsStyle = {fontSize:"1.5rem"}

    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a href="mailto:company@domain.com" style={fsStyle}>Laydeez_skin</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6 style={fsStyle}>Wechat</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p style={fsStyle}>Shop 1 / 19 Burwood Road, Burwood, NSW 2134 </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6 style={fsStyle}>Address</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a style={fsStyle} href="tel:005053255350539">0272089381</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6 style={fsStyle}>Phone</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description" style={itemStyle}>
                        <p>
                            <a style={fsStyle} href="tel:005053255350539">17 Riley Ln, Burwood, NSW, 2134</a>
                        </p>
                    </div>

                    <div className="contacts-item-title" style={itemStyle}>
                        <h6 style={fsStyle}>Parking address</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactsEn;