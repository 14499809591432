import React from 'react';
import { Link } from 'react-router-dom';

const ContactsUsEn = ({ addBgClass = true }) => {
    const itemStyle = {
        textAlign: 'left'
    };
    return (
        <section id="contact-us" className="block">
            <div className={addBgClass ? 'bg-pink-light spacer p-top-xl p-bottom-xl' : 'spacer p-top-xl p-bottom-xl'}>
                <div className="wrapper text-center">
                    <div className="title">
                        <h2>Welcome to Contact us</h2>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',gap:'2rem'}}>
                    <div id="img" className="block spacer p-top-l" style={{flex:'1'}}>
                            <div>
                                <h3 style={{  textAlign: "center", marginBottom: '0.985rem'}}>Sydney Branch</h3>
                            </div>
                            <div className="img-no-wrap-1">
                                <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/placeholder/大图.png" alt="Beauty Salon" />
                                        </div>
                                </div>
                            </div>
                            {/* <div style={{ paddingTop: '2rem' }}>
                                <h4>Laydeez skin laser clinic坐落于新南威尔士州悉尼中心最繁华的华人区Burwood的主街。</h4>
                                <p style={{padding:'1rem 0'}}>
                                    我们集：皮肤管理，抗衰治疗，镭射祛斑，产后修复，日式小颜术，为一体的多纬度的医学美容中心。我们拥有当下最先进的仪器以及十年以上经验丰富的团队，为您提供个性化和定制化的专业医美服务和产后修复服务。在每一位客人到来之际，我们都将用最诚挚的热情以及专业的角度为您所困扰的皮肤及各种问题提供专业的意见。</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="contacts-items">
                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                           
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    <a href="mailto:company@domain.com">Laydeez_skin</a>
                                                </p>
                                            </div>
                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Wechat</h6>
                                            </div>

                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                        
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>Shop 1 / 19 Burwood Road, Burwood, NSW 2134 </p>
                                            </div>
                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Address</h6>
                                            </div>

                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                        
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    <a href="tel:005053255350539">0272089381</a>
                                                </p>
                                            </div>
                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Phone</h6>
                                            </div>

                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                        
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    17 Riley Ln, Burwood, NSW, 2134
                                                </p>
                                            </div>
                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Parking address
                                                </h6>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ width: '200px', height: '200px' }}>
                                        <img style={{ width: '100%', height: '100%' }} src='assets/img/placeholder/Laydeez-wechat qrcode.png'></img>
                                    </div>
                                </div>
                            </div>      */}
                            <Link className="btn btn-outline-secondary" to={'/sydneyStoreEn'} style={{marginTop:'1rem'}}>Contact us</Link>
 
                        </div>
                        <div id="img" className="block spacer p-top-l" style={{flex:'1'}}>
                            <div>
                                <h3 style={{ textAlign: "center",marginBottom: '0.985rem' }}>Melbourne Branch</h3>
                            </div>
                            <div className="img-no-wrap-1">
                                <div className="img object-fit">
                                        <div className="object-fit-cover">
                                            <img src="assets/img/placeholder/moerben.png" alt="Beauty Salon" />
                                        </div>
                                </div>
                            </div>
                            {/* <div style={{ paddingTop: '2rem' }}>
                                <h4>Laydeez skin laser clinic坐落于新南威尔士州悉尼中心最繁华的华人区Burwood的主街。</h4>
                                <p style={{padding:'1rem 0'}}>
                                    我们集：皮肤管理，抗衰治疗，镭射祛斑，产后修复，日式小颜术，为一体的多纬度的医学美容中心。我们拥有当下最先进的仪器以及十年以上经验丰富的团队，为您提供个性化和定制化的专业医美服务和产后修复服务。在每一位客人到来之际，我们都将用最诚挚的热情以及专业的角度为您所困扰的皮肤及各种问题提供专业的意见。</p>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="contacts-items">
                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    <a href="mailto:company@domain.com">Laydeez_mel</a>
                                                </p>
                                            </div>

                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Wechat</h6>
                                            </div>
                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>Suite G01, 37-41 Prospect St, BoxHill, VIC, 3128 </p>
                                            </div>

                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Address</h6>
                                            </div>
                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    <a href="tel:005053255350539">0370529081</a>
                                                </p>
                                            </div>

                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Phone</h6>
                                            </div>
                                        </div>

                                        <div className="contacts-item" style={{ marginBottom: '1rem' }}>
                                            <div className="contacts-item-description" style={itemStyle}>
                                                <p>
                                                    <a href="tel:005053255350539">13 Prospect St, Parking, BoxHill, VIC, 3128</a>
                                                </p>
                                            </div>

                                            <div className="contacts-item-title" style={itemStyle}>
                                                <h6>Parking address</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '200px', height: '200px' }}>
                                        <img style={{ width: '100%', height: '100%' }} src='assets/img/placeholder/墨尔本微信.jpeg'></img>
                                    </div>
                                </div>
                            </div> */}
                            <Link className="btn btn-outline-secondary" to={'/melbourneStoreEn'} style={{marginTop:'1rem'}}>Contact us</Link>

                        </div>
                    </div>
                    {/* <a href={ process.env.PUBLIC_URL + "/contactsEn" } className="btn btn-outline-secondary">Our Contact Details</a> */}
                </div>
            </div>
        </section>
    );
};

export default ContactsUsEn;