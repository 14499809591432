import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside2 from '../blocks/page-title/PageTitleServicesInside2'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import Introduction from '../components/services/Introduction';

// 定义样式对象
const titleStyle = {
    flexShrink: "0",
    fontWeight: '600',
    color: '#333',
    fontSize:'1.5rem'
};

    
const contentStyle = { maxWidth: '80%',width: '100%', textAlign: 'left',fontSize:'1.5rem'}

const ServicesInside2 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />


            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside2 />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/placeholder/基础护理图片.png" alt="Hair Treatments" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p></p>

                                        </div>
                                    </div> */}

                                    <Introduction textIndent={true} list={['我们致力于为您提供一站式肌肤护理体验，帮助您实现健康亮丽的肌肤。我们的专业团队结合多种先进技术和高品质产品，提供多项护理服务。 无论您是想要深层清洁、补水保湿，还是针对痘痘和肌肤暗沉问题，我们都能为您量身定制个性化的护理方案。在这里，您不仅能享受到专业的服务，还能感受到放松与愉悦。期待您的光临，与您一起开启美丽之旅！']}></Introduction>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">服务项目</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}
                                                    
                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>深层清洁护理</span>
                                                            <span className="list-group-price" style={contentStyle}>通过深层清洁，去除肌肤表面和毛孔内的污垢，让肌肤焕发健康光彩。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>红酒祛痘护理</span>
                                                            <span className="list-group-price" style={contentStyle}>利用红酒的抗氧化特性，有效抑制痘痘生成，帮助肌肤恢复平滑。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>脸部淋巴排毒</span>
                                                            <span className="list-group-price" style={contentStyle}>通过淋巴排毒手法，促进血液循环，排出体内毒素，令肌肤更加紧致有弹性。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>水氧保湿护理</span>
                                                            <span className="list-group-price" style={contentStyle}>深层补水，锁住水分，让肌肤全天候保持水润光滑。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>冷导修复护理</span>
                                                            <span className="list-group-price" style={contentStyle}>通过冷导技术，促进细胞修复，改善肌肤状态，令肌肤更加细腻光滑。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>光疗提亮护理</span>
                                                            <span className="list-group-price" style={contentStyle}>利用光疗技术，均匀肤色，提亮肌肤，展现自然健康的光泽。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>小气泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>温和去角质，清除毛孔内杂质，帮助肌肤恢复明亮透彻的状态。</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>蓝光祛痘护理</span>
                                                            <span className="list-group-price" style={contentStyle}>蓝光技术针对痘痘问题，有效消炎杀菌，帮助肌肤恢复平衡。</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={{ flexShrink: "0", fontWeight:'600',color:'#333', fontWeight:'600',color:'#333' }}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}></span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <BackToServices />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside2;