import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AboutsUsEn = () => {
    const location = useLocation();
    const hash = location.hash;
    console.log(hash, 'sadasdasd');
    useEffect(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
            element.scrollIntoView(true);
        }
    }, []);
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">

                <div className="row gutter-width-sm"  id='moerben'>
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                        <div className="title">
                            <h2>Laydeez Laser Clinic is located in the heart of Box Hill, Melbourne, Victoria.</h2>
                        </div>
                    </div>
                </div>

                {/* <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>We are a multidimensional medical aesthetics center integrating skin management, anti-aging treatments, laser spot removal, postnatal repair, and Japanese facial slimming techniques. We boast the most advanced equipment and a team with over ten years of rich experience, providing personalized and customized professional medical aesthetics and postnatal repair services. Upon every client's arrival, we offer our sincerest enthusiasm and professional perspective to provide expert advice for your skin concerns and various issues.</p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="description">
                            <p>TAKES TREATMENT FIVE  OFF YOUR FACE</p>
                            <p>The FUTURE IS SPERB</p>
                            <p>100% NON-INVASIVE</p>
                            <p>QUICK Treatment</p>
                            <p>DOWNTIME</p>
                            <p>COMFORTABLE TREATMENT</p>
                        </div>
                    </div>
                </div> */}

                <div class="row gutter-width-sm justify-content-end">
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p style={{fontSize:"1.5rem"}}>Following our Burwood store in Sydney, we are excited to announce the opening of our second location in Melbourne. Our store is situated in the heart of Box Hill, Melbourne's largest Chinese community, and spans an impressive 200 square meters. We offer a comprehensive range of services, including postpartum care, Japanese facial bone alignment, skin management, and posture management. With our unwavering commitment, we continue to serve the Chinese community and women in Melbourne.</p>
                        </div>
                    </div>
                    {/* <div class="col-xl-2 col-lg-2 d-none d-lg-block"></div> */}
                    {/* <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div class="description">
                            <p>TAKES TREATMENT FIVE OFF YOUR FACE</p>
                            <p>The FUTURE IS SPERB</p>
                            <p>100% NON-INVASIVE</p>
                            <p>QUICK Treatment</p>
                            <p>DOWNTIME</p>
                            <p>COMFORTABLE TREATMENT</p>
                        </div> */}
                    {/* </div> */}
                </div>

            </div>
        </section >
    );
};

export default AboutsUsEn;
