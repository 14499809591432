import React, { Fragment,useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside7 from '../blocks/page-title/PageTitleServicesInside6'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import WhyComponent from '../components/services/WhyComponent';
import Introduction from '../components/services/Introduction';

const ServicesInside7 = () => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, []);
    const contentStyle = { 
        maxWidth: '80%',
        width: '100%', 
        textAlign: 'left',
        fontSize:'1.5rem'
    }
    const titleStyle = {
        flexShrink: "0",
        fontWeight: '600',
        color: '#333',
        fontSize:'1.5rem'
    };
    const whyList = [
        {
            title: '毛孔粗大',
            content: "使用 Dermapen 刺激皮肤产生新的胶原蛋白，使粗大的毛孔变得饱满并收紧。"
        },
        {
            title: '祛痘印痘坑',
            content: "Dermapen 4 是一款具有专用疤痕设置和方案的设备，可修复所有疤痕类型（包括痤疮疤痕）。"
        },
        {
            title: '色素沉着',
            content: "与某些激光治疗不同，Dermapen 消除了热损伤和色素沉着不足的风险，同时促进优化细胞功能。"
        },
        {
            title: '妊娠纹',
            content: "许多从业者和患者声称 Dermapen Treatments™ 在治疗妊娠纹方面比其他方法更有效。"
        },
        {
            title: '粉刺',
            content: "Dermapen 治疗可显着改善问题性皮肤痘痘、黑头或白头（粉刺）以及活动性痤疮的外观。"
        },
        {
            title: '抗衰老',
            content: "Dermapen 微针程序可促进身体的自然愈合反应，增加胶原蛋白和弹性蛋白的产生，从而减少皱纹和细纹。"
        },
    ]

    // const WhyComponent = (list = []) => {
    //     return (
    //         <div className="block spacer p-top-xl">
    //             <div className="title">
    //                 <h2 className="hr">为什么选择 DP？</h2>
    //             </div>
    //             <div>
    //                 {whyList.length > 0 && whyList.map(item => {
    //                     return <div>
    //                         <div>{item.title}</div>
    //                         <div>{item.content}</div>
    //                     </div>
    //                 })}
    //             </div>
    //         </div>
    //     )
    // }


    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Service inside | Mykery - Beauty Salon React JS Template</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="dark"  position='relative'  />

            <main id="main" className="site-main" style={{paddingTop:"7.368rem",paddingBottom:"7.368rem"}}>
                <PageTitleServicesInside7 />


                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover" style={{display:"flex",justifyContent:"space-around",columnGap:"1rem"}}>
                                                <div style={{maxHeight:'700px',flex:'50%'}}><img src="assets/img/placeholder/DP仪器.jpg" alt="Hair Treatments" /></div>
                                                <div style={{maxHeight:'700px',flex:'50%'}}><img src="assets/img/placeholder/DP治疗.jpg" alt="Hair Treatments" /></div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
                                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.</p>
                                        </div>
                                    </div> */}

                 
                                    <Introduction textIndent={true} list={['']} tips={'DP 治疗重写肌肤历史，刺激弹性蛋白和胶原蛋白的产生，激发皮肤活性。' } title=''></Introduction>

                                    <WhyComponent list={whyList}  title='为什么选择 DP？'/>


                                    {/* <div className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">为什么选择 DP？</h2>
                                        </div>
                                        <div>

                                        </div>

                                    </div> */}

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr">治疗范围</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Special in-salon treatments</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>凹凸洞</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>痘印痘疤</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>敏感泛红</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90-120 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>改善细纹</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>收缩毛孔</span>
                                                            {/* <span className="list-group-price" style={contentStyle}>90 mins</span> */}
                                                        </li>
                                                        <BackToServices />
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Haircut and hair styling</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>玻尿酸瀑布护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90-120 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>氧气泡泡护理</span>
                                                            <span className="list-group-price" style={contentStyle}>90 mins</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}

                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair styling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Consulting</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Trial hairstyling for occasion</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>

                                                <div className="price-list-item">
                                                    {/* <div className="price-list-item-title">
                                                        <h3>Hair coloring</h3>
                                                    </div> */}

                                                    <ul className="price-list-item-list-group list-group">
                                                        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>All hair color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Root color</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Highlights</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title" style={titleStyle}>Hair toning</span>
                                                            <span className="list-group-price" style={contentStyle}>From 35 $</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    );
};

export default ServicesInside7;